import classnames from "classnames";
import type { ReactNode } from "react";

import Styles from "./footer.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
};

export function TransactionCreationPageFooter({ children, className }: Props) {
  const cx = classnames(className, Styles.footer);
  return <footer className={cx}>{children}</footer>;
}
