import "./index.scss";

import { PureComponent, memo } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import { useSelector } from "redux/util";
import { UserTermsOfService, MasterPrivacyPolicyUrl, AdditionalTrialTerms } from "common/tos";
import { computeDefaultType, canLoginWithGoogle } from "common/account/util";
import { useIsAuthenticated } from "common/authentication";
import { loginReset, EXISTING_DOMAIN } from "redux/actions/authentication";
import { handleCreateAccount, handleGoogleSignIn } from "util/sign_up";
import { getTierFromPath, getTierName } from "util/tier";
import { useApolloClient } from "util/graphql";
import { isMobileDevice } from "util/support";
import { Tier } from "graphql_globals";
import { useViewer } from "util/viewer_wrapper";
import APP from "constants/applications";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { MOBILE_URL } from "constants/marketing";
import { useFeatureFlag } from "common/feature_gating";
import { useMatchScreenClass } from "common/core/responsive";
import { useProMobileOnboarding } from "util/feature_detection";

import { TrialReason } from "./form/fields/types";
import SignupBusinessFields from "./form/fields/business";
import SignupStarterFields from "./form/fields/starter";
import SignupProTrialFields from "./form/fields/pro_trial";
import { WrappedSignupForm } from "./form";
import GraphicContainer, {
  GraphicContainerContentFooter,
  GraphicContainerContentHeader,
} from "./graphic_container";
import ProofCard from "../login/proof/card";

export const MESSAGES = defineMessages({
  [Tier.ELITE]: {
    id: "24716e72-3495-4d14-84ec-ddfe0905a869",
    defaultMessage: "Notarize | Premium Plan",
  },
  [Tier.PRO]: {
    id: "f165fc89-655b-4cba-b837-a6d7413c801d",
    defaultMessage: "Notarize | Pro Plan",
  },
  [Tier.PRO_TRIAL]: {
    id: "52e1f21e-9e94-4546-9c75-a1dda9829c33",
    defaultMessage: "Notarize | Pro Trial",
  },
  [Tier.BUSINESS_PRO_ODN]: {
    id: "922648a4-51c7-46b2-90e3-56d375981c4f",
    defaultMessage: "Notarize | Pro Plan",
  },
  [Tier.BUSINESS_PRO_IHN]: {
    id: "bcaf7e8c-b8c7-4216-827a-44c6cfc1fbc3",
    defaultMessage: "Notarize | Pro Plan",
  },
  [Tier.BASIC]: {
    id: "816fa871-a8be-4535-9cf5-f93207050e2a",
    defaultMessage: "Notarize | Starter Plan",
  },
});

export function getFields({ tier, selectedAccount }) {
  if (selectedAccount === APP.TITLE_AGENCY) {
    return SignupStarterFields;
  }

  switch (tier) {
    case Tier.ELITE:
    case Tier.PRO:
    case Tier.BUSINESS_PRO_IHN:
    case Tier.BUSINESS_PRO_ODN:
      return SignupBusinessFields;
    case Tier.PRO_TRIAL:
      return SignupProTrialFields;
    default:
      return SignupStarterFields;
  }
}

export function handleEmailSubmit(
  formData,
  apolloClient,
  tierPath,
  refetchViewer,
  domainMatchId,
  setDomainMatchId,
  authParams,
  marketingSource,
) {
  const { dispatch, computedEntry, computedAccountType, redirectUrl, tierName } = authParams;
  const tier = getTierFromPath({ path: tierPath });
  const domainCheck = !domainMatchId && [Tier.TITLE_PRO_IHN, Tier.TITLE_PRO_ODN].includes(tier);

  return handleCreateAccount({
    dispatch,
    entry: computedEntry,
    accountType: computedAccountType,
    formData,
    redirectUrl,
    apolloClient,
    domainCheck,
    marketingSource,
    tierName,
  })
    .then(refetchViewer)
    .catch((err) => {
      if (err.body?.errors === EXISTING_DOMAIN) {
        setDomainMatchId(err.body.id);
        return;
      }
      throw err;
    });
}

export function handleGoogleSubmit(authCode, refetchViewer, authParams) {
  const { dispatch, computedEntry, computedAccountType, redirectUrl, tierName } = authParams;

  return handleGoogleSignIn(
    dispatch,
    computedEntry,
    computedAccountType,
    authCode,
    redirectUrl,
    tierName,
  ).then(refetchViewer);
}

class Signup extends PureComponent {
  state = {
    domainMatchId: null,
  };

  componentWillUnmount() {
    if (this.originalTitle) {
      document.title = this.originalTitle;
    }
    this.props.dispatch(loginReset());
  }

  getDeriveDisabledFromFormValues({ tier }) {
    if (tier === Tier.PRO_TRIAL) {
      return (formValues) => formValues.trialReason !== TrialReason.Business;
    }

    return null;
  }

  getTitle(tier) {
    if (tier === Tier.PRO_TRIAL) {
      return (
        <FormattedMessage
          id="7394000e-a335-4e04-a55f-cf76f81b4cc3"
          defaultMessage="Start your 30-day free trial"
        />
      );
    }

    return null;
  }

  getMessage(tier) {
    if (tier === Tier.PRO_TRIAL) {
      return (
        <FormattedMessage
          id="e194bead-9611-48af-bc67-10a3b24361ca"
          description="trial no credit card"
          defaultMessage="No credit card required to sign up"
        />
      );
    }

    return null;
  }

  getDisclaimer(tier) {
    if (tier === Tier.PRO_TRIAL) {
      return <AdditionalTrialTerms />;
    }

    return null;
  }

  getTos(tier) {
    if (tier === Tier.PRO_TRIAL) {
      return (
        <>
          <FormattedMessage
            id="f4f8289a-d651-4796-8884-fc36cb3f39be"
            defaultMessage="By clicking 'Start free trial', you agree to the {termsOfUse}. You also agree to the Free Trial Additional Terms and Conditions*. For information on our privacy and data use practices please see our {privacyPolicy}."
            values={{
              termsOfUse: <UserTermsOfService underlined />,
              privacyPolicy: <MasterPrivacyPolicyUrl underlined />,
            }}
          />
          <br /> <br />
          {this.getDisclaimer(tier)}
        </>
      );
    }

    return null;
  }

  getSubmitText(tier) {
    switch (tier) {
      case Tier.PRO_TRIAL:
        return (
          <FormattedMessage
            id="00644660-088d-45f5-a4b7-eeaeb9406007"
            defaultMessage="Start free trial"
          />
        );
      case Tier.PRO:
      case Tier.ELITE:
        return (
          <FormattedMessage
            id="23afc530-a4b8-4e2d-83af-1a79ad9fda07"
            defaultMessage="Continue to payment"
          />
        );
      case Tier.BUSINESS_PRO_IHN:
      case Tier.BUSINESS_PRO_ODN:
        return (
          <FormattedMessage id="ae445dbb-344d-49b9-a44f-92eecd5da0f8" defaultMessage="Sign up" />
        );
      default:
        return (
          <FormattedMessage id="3fb9d002-66da-4c71-9117-079bf5e782d3" defaultMessage="Sign up" />
        );
    }
  }

  getAuthParams() {
    const {
      dispatch,
      tierPath,
      selectedAccount,
      entry,
      signupTosEnabled,
      proMobileOnboardingEnabled,
    } = this.props;
    const computedEntry = computeDefaultType(entry);
    const computedAccountType = computeDefaultType(selectedAccount);
    const tier = getTierFromPath({ path: tierPath });
    const tierName = signupTosEnabled ? getTierName(tier) : null;
    const needsUpgrade = !tierName && ![Tier.BASIC, Tier.TITLE_AGENT].includes(tier);

    const upgradeUrl = `/upgrade?tier=${tierPath}`;
    // GRW-707 - Revisit with bug fix for MOBILE_URL (broken link currently). Might not need.
    const proMobileOnboardingFlow = proMobileOnboardingEnabled && tier === Tier.BUSINESS_PRO_ODN;
    const nonUpgradeUrl = isMobileDevice() && !proMobileOnboardingFlow ? MOBILE_URL : "/";
    const redirectUrl = needsUpgrade ? upgradeUrl : nonUpgradeUrl;

    return { dispatch, computedEntry, computedAccountType, redirectUrl, tierName };
  }

  handleEmailSubmit = (formData) => {
    const { apolloClient, tierPath, refetchViewer } = this.props;

    return handleEmailSubmit(
      formData,
      apolloClient,
      tierPath,
      refetchViewer,
      this.state.domainMatchId,
      (id) => this.setState({ domainMatchId: id }),
      this.getAuthParams(),
      this.props.searchParams.get("ref"),
    );
  };

  handleGoogleSubmit = (authCode) => {
    return handleGoogleSubmit(authCode, this.props.refetchViewer, this.getAuthParams());
  };

  render() {
    const {
      authentication,
      isAuthenticated,
      isExtraSmall,
      searchParams,
      selectedAccount,
      tierPath,
    } = this.props;

    if (isAuthenticated) {
      return <Navigate to="/slideshow" />;
    } else if (!isAuthenticated && authentication.accountStatus.needsPassword) {
      return <Navigate to="/verification-email-sent" />;
    }

    const tier = getTierFromPath({ path: tierPath });

    const isTitleAgent = selectedAccount === APP.TITLE_AGENCY;
    const isCustomer = selectedAccount === APP.CUSTOMER;

    return (
      <GraphicContainer>
        <ProofCard
          noBodyMargin
          header={
            <GraphicContainerContentHeader
              title={
                isCustomer ? (
                  <FormattedMessage
                    id="6450642a-4d84-4b96-9df0-2d8bdf5b108c"
                    defaultMessage="Create your Proof account"
                  />
                ) : isTitleAgent ? (
                  <FormattedMessage
                    id="c6f82b11-8cb8-4096-aff1-3abee7132670"
                    defaultMessage="Create your Proof account for Title"
                  />
                ) : (
                  <FormattedMessage
                    id="f3a53ee5-6bcc-4c0a-ab31-00c0f98e1f64"
                    defaultMessage="Join Proof for Business"
                  />
                )
              }
            />
          }
          body={
            <WrappedSignupForm
              onCommit={this.handleEmailSubmit}
              onGoogle={this.handleGoogleSubmit}
              canLoginWithGoogle={canLoginWithGoogle({ tier, selectedAccount })}
              title={this.getTitle(tier)}
              message={this.getMessage(tier)}
              tos={this.getTos(tier)}
              submitText={this.getSubmitText(tier)}
              initialValues={{ email: searchParams.get("email") }}
              Fields={getFields({ tier, selectedAccount })}
              deriveDisabledFromFormValues={this.getDeriveDisabledFromFormValues({ tier })}
              domainMatchId={this.state.domainMatchId}
              businessRequired={!isCustomer}
              isCustomer={isCustomer}
            />
          }
          footer={!isExtraSmall && <GraphicContainerContentFooter />}
        />
      </GraphicContainer>
    );
  }
}

Signup.propTypes = {
  tierPath: PropTypes.string,
  selectedAccount: PropTypes.string,
  entry: PropTypes.string,
  isExtraSmall: PropTypes.bool,
};

/** @type { (props: Record<string, unknown>) => React.ReactElement } */
const Wrapper = memo((props) => {
  const intl = useIntl();
  const { tierPath } = props;
  const tier = getTierFromPath({ path: tierPath });
  const documentTitles = useDocumentTitles();
  const message = documentTitles[tier];
  const proMobileOnboardingEnabled = useProMobileOnboarding();
  const isExtraSmall = useMatchScreenClass("xs") && proMobileOnboardingEnabled;
  const signupTosEnabled = useFeatureFlag("signup-tos");

  useA11y().useDocumentEntitler({
    title: message
      ? intl.formatMessage(message)
      : intl.formatMessage(documentTitles.signUpFallback),
  });

  return (
    <Signup
      {...props}
      apolloClient={useApolloClient()}
      navigate={useNavigate()}
      dispatch={useDispatch()}
      refetchViewer={useViewer().refetch}
      isAuthenticated={useIsAuthenticated()}
      authentication={useSelector((state) => state.authentication)}
      intl={intl}
      searchParams={useSearchParams()[0]}
      signupTosEnabled={signupTosEnabled}
      isExtraSmall={isExtraSmall}
    />
  );
});

export default Wrapper;
