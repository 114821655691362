import type { ReactNode } from "react";

import { QueryWithLoading } from "util/graphql/query";
import { SurveyQuestions } from "graphql_globals";

import SlideshowQuery, {
  type Slideshow_viewer_user,
  type Slideshow_viewer_notaryUsStates,
} from "./slideshow_query.graphql";
import { SlideshowOnboarding } from "./slideshow_onboarding";
import { userCanSkipOnboarding } from ".";

export const SLIDESHOW_TAG_V2 = "business_onboarding_slideshow_v2";

export type SlideshowProps = {
  user: Slideshow_viewer_user;
  notaryUsStates: (Slideshow_viewer_notaryUsStates | null)[];
  children?: ReactNode;
};

export function OnboardingSlideshow({ children }: { children: ReactNode }) {
  const variables = {
    slideshowQuestion: SurveyQuestions.MOST_IMPORTANT_FEATURES,
    slideshowTags: [SLIDESHOW_TAG_V2],
  };

  return (
    <QueryWithLoading query={SlideshowQuery} variables={variables}>
      {({ data }) => {
        const user = data!.viewer.user!;
        const notaryUsStates = data!.viewer.notaryUsStates;
        const industryOptions = data!.viewer.industryOptions;

        if (userCanSkipOnboarding(user)) {
          return <>{children}</>;
        }

        return (
          <SlideshowOnboarding
            user={user}
            notaryUsStates={notaryUsStates}
            industryOptions={industryOptions}
          >
            {children}
          </SlideshowOnboarding>
        );
      }}
    </QueryWithLoading>
  );
}
