import { Outlet } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import Dashboard from "common/dashboard";
import { Feature } from "graphql_globals";
import { useActiveOrganization } from "common/account/active_organization";
import { useQuery } from "util/graphql";
import { BusinessDashboardMobileDevice } from "common/proof_frame/mobile_nav/empty_states";

import { BusinessDashboardSidebar } from "./sidebar";
import BusinessDashboardWrapperGraph, {
  type BusinessDashboard_node_Organization as ActiveOrganization,
  type BusinessDashboard_viewer as Viewer,
} from "./index.query.graphql";
import Styles from "./index.module.scss";

export const BIZ_TEMPLATES_ANNOUNCEMENT_DISMISSED_TAG = "new-feature-business-templates-popup";
export const BIZ_REFERRALS_ANNOUNCEMENT_DISMISSED_TAG = "referral-announcement-dismissed";

type DashboardProps = {
  activeOrganization: ActiveOrganization;
  viewer: Viewer;
};

function DashboardWrapper({ viewer, activeOrganization }: DashboardProps) {
  const user = viewer.user!;
  const showCreatorGroups = activeOrganization.featureList.includes(Feature.TEAM_MANAGEMENT);

  return (
    <div className={Styles.businessDashboard}>
      <Dashboard>
        <BusinessDashboardSidebar
          showCreatorGroups={showCreatorGroups}
          activeOrganization={activeOrganization}
          user={user}
        />
        <Outlet />
      </Dashboard>
    </div>
  );
}

function BusinessDashboardDesktop() {
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(BusinessDashboardWrapperGraph, {
    variables: {
      organizationId: activeOrganizationId!,
      tagList: [BIZ_TEMPLATES_ANNOUNCEMENT_DISMISSED_TAG, BIZ_REFERRALS_ANNOUNCEMENT_DISMISSED_TAG],
    },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const viewer = data!.viewer;
  const organization = data!.node!;

  if (organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  return <DashboardWrapper activeOrganization={organization} viewer={viewer} />;
}

export function BusinessDashboard({
  showMobileDeviceContent,
}: {
  showMobileDeviceContent: boolean;
}) {
  return showMobileDeviceContent ? <BusinessDashboardMobileDevice /> : <BusinessDashboardDesktop />;
}
