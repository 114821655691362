import { PureComponent, memo } from "react";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { defineMessages, useIntl } from "react-intl";

import { encodeSearchParams } from "util/location";
import LoadingIndicator from "common/core/loading_indicator";
import FormattedPrice from "common/core/format/formatted_price";
import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import CreateOrganizationMutation from "util/apollo_graphql/mutations/create_organization/mutation.graphql";
import { TIER_NAME } from "constants/tier";
import { getTierFromPath, getTierPricingUrl } from "util/tier";
import { useMutation } from "util/graphql";
import { QueryWithLoading } from "util/graphql/query";
import { addError } from "redux/actions/errors";
import { includesOrgAdminRole } from "util/organization";
import { hardNavigateTo } from "util/navigation";
import { isMobileDevice } from "util/support";
import { captureException } from "util/exception";
import {
  useActiveOrganization,
  ActiveOrganizationProvider,
} from "common/account/active_organization";
import { MOBILE_URL } from "constants/marketing";
import { OrganizationAccountCreation } from "graphql_globals";

import UpdateOrganizationTierMutation from "./update_organization_tier_mutation.graphql";
import VIEWER_QUERY from "./viewer_query.graphql";
import TierPayment from "../payment";

const messages = defineMessages({
  title: {
    id: "691d7774-3d1e-4093-9655-e7fbe4e09408",
    defaultMessage: "Payment",
  },
  unexpectedError: {
    id: "0f58e05a-8b73-45cb-aa76-eb818848093e",
    defaultMessage: "An unexpected error occured. Please try again or contact support.",
  },
  supportTitle: {
    id: "3b643775-8865-49da-b312-5bbb6ddbd175",
    defaultMessage: "Contact Support",
  },
  supportMessage: {
    id: "956cee2f-a883-46d4-a48f-c472e4bc13fe",
    defaultMessage: "Please contact support to switch to this plan.",
  },
  needAdminTitle: {
    id: "75987802-2d8a-433a-937e-05dae916bd92",
    defaultMessage: "Contact An Admin",
  },
  needAdminMessage: {
    id: "71f569ba-43eb-41f8-a64b-b768ffaeadbb",
    defaultMessage: "Only admins can change plans.",
  },
  ok: {
    id: "f1d0da01-8842-4f4f-a660-d14a4073b05d",
    defaultMessage: "Ok",
  },
  pricingPage: {
    id: "cd308c64-246e-4ebd-ab40-66bdabb1e83c",
    defaultMessage: "Pricing Page",
  },
});

const UI = {
  PAYMENT: "PAYMENT",
  SUPPORT: "SUPPORT",
  NEED_ADMIN: "NEED_ADMIN",
};
const INVALID_TIER_ERROR_CODE = "tier_update_invalid";

class TierUpgrade extends PureComponent {
  constructor(props) {
    super(props);

    const newTier = getTierFromPath({ path: this.props.searchParams.get("tier") || "" });
    this.newTierName = TIER_NAME[newTier];
  }

  state = {
    ui: null,
  };

  componentDidMount() {
    this.startUpgradeOrRedirect();
  }

  getSelectedTier() {
    const { viewer } = this.props;
    const tiers = viewer.tiers.edges.map(({ node }) => node);
    return tiers.find(({ name }) => name === this.newTierName);
  }

  handleTierUpgrade = async (orgId) => {
    const { updateOrganizationTier, dispatch, intl, navigate } = this.props;

    try {
      await updateOrganizationTier({
        variables: {
          mutationInput: {
            organizationId: orgId,
            tierName: this.newTierName,
          },
        },
      });

      if (isMobileDevice()) {
        // GRW-707 - Revisit with bug fix for MOBILE_URL (broken link currently). Might not need.
        hardNavigateTo(MOBILE_URL);
      } else {
        navigate("/", { replace: true });
      }
    } catch (error) {
      if (error.message.includes(INVALID_TIER_ERROR_CODE)) {
        this.setState({ ui: UI.SUPPORT });
      } else {
        captureException(error);
        dispatch(addError(intl.formatMessage(messages.unexpectedError)));
      }
    }
  };

  startUpgradeOrRedirect() {
    const { viewer } = this.props;
    const { user } = viewer;
    if (!user.organization || includesOrgAdminRole(user?.roles)) {
      this.startUpgrade();
    } else {
      this.setState({ ui: UI.NEED_ADMIN });
    }
  }

  async startUpgrade() {
    const {
      createOrganization,
      viewer: { user },
      intl,
      dispatch,
      setActiveOrg,
    } = this.props;

    let orgId = user.organization?.id;
    try {
      if (!orgId) {
        const { data } = await createOrganization({
          variables: {
            input: {
              userId: user.id,
              accountCreation: OrganizationAccountCreation.USER_INITIATED_UPGRADE,
            },
          },
          update: (store, { data: { createOrganization } }) => {
            const data = store.readQuery({ query: VIEWER_QUERY });
            const newData = {
              ...data,
              viewer: {
                ...data.viewer,
                user: {
                  ...data.viewer.user,
                  organization: {
                    __typename: "Organization",
                    id: createOrganization.organization.id,
                  },
                },
              },
            };
            store.writeQuery({ query: VIEWER_QUERY, data: newData });
          },
        });
        orgId = data.createOrganization.organization.id;
      }

      setActiveOrg(orgId);
      const selectedTier = this.getSelectedTier();
      if (!selectedTier) {
        this.setState({ ui: UI.SUPPORT });
      } else if (
        selectedTier.prices.platformSubscription > 0 ||
        selectedTier.prices.platformMinimumCommitment > 0
      ) {
        this.setState({ ui: UI.PAYMENT });
      } else {
        // upgrade without collecting payment when no platform subscription fee
        await this.handleTierUpgrade(orgId);
      }
    } catch (error) {
      captureException(error);
      dispatch(addError(intl.formatMessage(messages.unexpectedError)));
    }
  }

  goToPricingPage = () => {
    const { name: tierName } = this.props.viewer.user.activeTier;

    hardNavigateTo(getTierPricingUrl({ tierName }));
  };

  render() {
    const { viewer, intl, navigate } = this.props;
    const { ui } = this.state;

    if (ui === UI.PAYMENT && viewer.user.organization) {
      const selectedTier = this.getSelectedTier();
      const headerButtons = (
        <>
          <Button
            automationId="tier-upgrade-pricing-button"
            onClick={this.goToPricingPage}
            buttonColor="light"
            variant="secondary"
          >
            {intl.formatMessage(messages.pricingPage)}
          </Button>
        </>
      );
      const totalCost =
        selectedTier.prices.platformSubscription + selectedTier.prices.platformMinimumCommitment;
      const hasDelayedCharge =
        selectedTier.options.chargePlatformSubscriptionRetroactively ||
        selectedTier.prices.platformMinimumCommitment > 0;
      return (
        <TierPayment
          onPaymentSaved={() => this.handleTierUpgrade(viewer.user.organization.id)}
          title={intl.formatMessage(messages.title)}
          tierName={selectedTier.displayName}
          tierPrice={<FormattedPrice cents={totalCost} hideFractionDigits />}
          tierInterval="month" // TODO: query for actual payment interval (RSMB-2606)
          headerButtons={headerButtons}
          showDelayedChargeMessage={hasDelayedCharge}
          showSubscriptionTos
        />
      );
    }

    if (ui === UI.SUPPORT) {
      const buttons = [
        <Button key="ok" onClick={() => navigate(-1)} buttonColor="action" variant="primary">
          {intl.formatMessage(messages.ok)}
        </Button>,
      ];
      return (
        <IllustrationModal
          title={intl.formatMessage(messages.supportTitle)}
          buttons={buttons}
          automationPrefix="upgrade-support-modal"
        >
          {intl.formatMessage(messages.supportMessage)}
        </IllustrationModal>
      );
    }

    if (ui === UI.NEED_ADMIN) {
      const buttons = [
        <Button key="ok" onClick={() => navigate(-1)} buttonColor="action" variant="primary">
          {intl.formatMessage(messages.ok)}
        </Button>,
      ];
      return (
        <IllustrationModal
          title={intl.formatMessage(messages.needAdminTitle)}
          buttons={buttons}
          automationPrefix="upgrade-need-admin-modal"
        >
          {intl.formatMessage(messages.needAdminMessage)}
        </IllustrationModal>
      );
    }

    return <LoadingIndicator />;
  }
}

TierUpgrade.propTypes = {
  // TierUpgradeContainer
  viewer: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      activeTier: PropTypes.shape({
        tier: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
      }).isRequired,
      organization: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
      organizationMembership: PropTypes.shape({
        role: PropTypes.string.isRequired,
      }),
    }).isRequired,
    tiers: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            prices: PropTypes.shape({
              platformSubscription: PropTypes.number.isRequired,
            }).isRequired,
          }).isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }),
  updateOrganizationTier: PropTypes.func.isRequired,
  createOrganization: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

TierUpgrade.defaultProps = {
  user: null,
};

function TierUpgradeWithActiveOrg(props) {
  const [, setActiveOrg] = useActiveOrganization();
  return <TierUpgrade {...props} setActiveOrg={setActiveOrg} />;
}

function TierUpgradeRedirect({ searchParams }) {
  const tier = searchParams.get("tier");
  const email = searchParams.get("email");
  const search = email ? encodeSearchParams(new URLSearchParams({ email })) : "";
  return (
    <>
      <LoadingIndicator />
      <Navigate to={`/signup/${tier}?${search}`} replace />
    </>
  );
}

function TierUpgradeContainer(props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const updateOrganizationTier = useMutation(UpdateOrganizationTierMutation);
  const createOrganization = useMutation(CreateOrganizationMutation);

  return (
    <ActiveOrganizationProvider>
      <QueryWithLoading query={VIEWER_QUERY}>
        {(query) =>
          query.data.viewer.user ? (
            <TierUpgradeWithActiveOrg
              {...props}
              intl={intl}
              navigate={navigate}
              dispatch={dispatch}
              searchParams={searchParams}
              viewer={query.data.viewer}
              updateOrganizationTier={updateOrganizationTier}
              createOrganization={createOrganization}
            />
          ) : (
            <TierUpgradeRedirect searchParams={searchParams} viewer={query.data.viewer} />
          )
        }
      </QueryWithLoading>
    </ActiveOrganizationProvider>
  );
}

export default memo(TierUpgradeContainer);
