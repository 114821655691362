import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { type ReactNode, useEffect, useState, useMemo } from "react";

import {
  SigningRequirementEnum,
  AuthTypes,
  DocumentRequirementEnum,
  type ProofRequirementInputType,
  type SignerCapacityTypes,
  VestingTypesEnum,
} from "graphql_globals";
import { MAX_SIGNERS } from "constants/transaction";
import { useFieldArray, useWatch } from "common/core/form";
import { SpacedMultipartFormRow } from "common/core/form/layout";
import { TextInput } from "common/core/form/text";
import {
  Card,
  SectionHeader,
  ConfiguredField,
  ConfiguredEmailField,
  ConfiguredStyledSelectField,
  requiredField,
  showField,
  readonlyField,
  downgradeDisplay,
} from "common/transaction_creation/v3/common";
import type { SectionContract, SectionComponentProps } from "common/transaction_creation/v3/form";
import { CheckboxLabel, Checkbox } from "common/core/form/option";
import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";
import type { Address } from "common/core/form/address";
import {
  blankRequirementForSubmit,
  transformProofRequirementForForm,
  transformProofRequirementForSubmit,
  ProofRequirementFormValues,
  type ProofRequirementField,
} from "common/proof_requirements/common";
import { usePermissions } from "common/core/current_user_role";
import { FORM_FIELDS as SIGNING_DETAILS_FORM_FIELDS } from "common/transaction_creation/v3/sections/signing_details";
import {
  RECIPIENT_GROUPS_UI,
  MIXED_SIGNING_UI_SUPPORT,
  SIGNING_ORDER_UI_SUPPORT,
} from "constants/feature_gates";
import { isFeatureEnabled } from "util/feature_detection";
import { usersOrgCreatedTransaction } from "common/transaction_creation/v3/real_estate/util";
import BinaryToggle from "common/form/inputs/binary_toggle";
import { useRecipientColors } from "common/pdf/recipient_colors/context";

import { AddCardButtonWrapper, AddCardButton, CardWrapper } from "./common";
import { SigningRequirement } from "./signing_requirement";
import { AddressSection, useRecipientAddressSections } from "./address";
import { PhoneNumberFields } from "./phone";
import { ProofRequirements } from "./proof_requirements";
import { Capacities } from "./capacities";
import type { RecipientDetails } from "./transaction_fragment.graphql";
import type { RecipientDetailsOrg } from "./organization_fragment.graphql";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  recipientDetails: {
    id: "4ce0157f-92bc-4b92-8e44-3a11a22d5261",
    defaultMessage: "Recipient details",
  },
  signer: {
    id: "6fcd4189-34cc-4b5d-b2f7-27dea4f51ec2",
    defaultMessage: "Signer",
  },
  signerDescription: {
    id: "7f497afd-1cfe-409f-8f56-cf4877bb9b9d",
    defaultMessage: "Signers can notarize or eSign documents.",
  },
  firstName: {
    id: "f40d0823-aca7-4e7f-86f8-adf5f311b2f9",
    defaultMessage: "First name",
  },
  middleName: {
    id: "82240748-e764-4cc6-b075-4f1aa4452ce0",
    defaultMessage: "Middle name",
  },
  lastName: {
    id: "49e11f24-3694-48ae-8811-8ef21f41d9c1",
    defaultMessage: "Last name",
  },
  email: {
    id: "fff94679-3afa-47c3-92c0-279630ea4673",
    defaultMessage: "Email address",
  },
  addRecipient: {
    id: "378b738f-050c-4dc9-846a-8dfbc913c741",
    defaultMessage: "Add recipient {number}",
  },
  removeRecipient: {
    id: "4a71685d-8801-47f2-a7cb-a1f628e4900d",
    defaultMessage: "Remove recipient {number}",
  },
  addAddress: {
    id: "441a49db-61fd-4382-a285-37341376be78",
    defaultMessage: "Add address",
  },
  toggleAddressAriaLabel: {
    id: "1549be5b-2ca1-4dd1-90f3-23257faec8cb",
    defaultMessage: "{action} address for recipient {number}",
  },
  representativeSigner: {
    id: "bde25e48-46dd-4d1e-aef8-af9cc4887096",
    defaultMessage: "Representative signer",
  },
  representativeSignerDescription: {
    id: "9398af8e-2a93-41b3-96c3-683efc33a373",
    defaultMessage:
      "Representative signers can notarize or eSign documents on behalf of another person or entity.",
  },
  toolTipPersonallyKnownToNotary: {
    id: "55b622e8-6280-487b-9f6a-72f536687727",
    defaultMessage: "Personally known to notary, max one signer conditions",
  },
  toolTipMaxRecipients: {
    id: "f9cdc5cb-a65f-4a60-aa5c-208a4f548628",
    defaultMessage: "Max additional recipients",
  },
  actionMenu: {
    id: "7f4b469e-7c46-4595-aaf8-5a7f72a7902c",
    defaultMessage: "Action menu for recipient {number}",
  },
  duplicateEmailError: {
    id: "2528b7ae-a726-4b66-b3a4-cab323459d86",
    defaultMessage: "Email has already been used",
  },
  // NOTE: The copy says 'Signing group' because that is the language we want to use right now.
  // This may change later when we start adding more actions for recipient groups.
  recipientGroup: {
    id: "f8259ded-5784-4efa-9b6c-e86c42ab0c93",
    defaultMessage: "Signing group",
  },
  recipientGroupDescription: {
    id: "abb81c6a-9444-4272-8539-ad3ac8f26824",
    defaultMessage:
      "The documents will be sent to a group of signers. One signer from the group can complete the signing.",
  },
  sharedInboxEmail: {
    id: "6888f5f7-f0b3-4b67-82cf-7e079d207c72",
    defaultMessage: "Group email address",
  },
  vestingType: {
    id: "25f3fc7d-3ab6-4db5-8b5f-6aa67bf3dc47",
    defaultMessage: "Vesting type",
  },
  signingOrderInputLabel: {
    id: "6be433be-5c2a-450e-9fcf-96afa2219cbc",
    defaultMessage: "Signing order {number}",
  },
});

export const RECIPIENT_DETAILS_SECTION_ID = "transaction-creation-section-recipient-details";

export type Form = SectionComponentProps<RecipientDetails, RecipientDetailsOrg, unknown>["form"];
export type Config = SectionComponentProps<
  RecipientDetails,
  RecipientDetailsOrg,
  unknown
>["config"];
export type FormCapacity = {
  capacityId: string | undefined;
  representativeOf: string;
  capacityType: SignerCapacityTypes | undefined;
  capacity: string | null;
};

type SubmitCapacity = {
  id: string | undefined;
  representativeOf: string;
  capacityType: SignerCapacityTypes;
  capacity: string | null;
};

const CONFIGS = {
  signerFirstName: "signerFirstName",
  signerMiddleName: "signerMiddleName",
  signerLastName: "signerLastName",
  signerEmail: "signerEmail",
  signerCountryCode: "signerCountryCode",
  signerPhoneNumber: "signerPhoneNumber",
  signerSigningRequirement: "signerSigningRequirement",
  signerProofRequirements: "signerProofRequirements",
  signerSharedInboxEmail: "signerSharedInboxEmail",
  signerAddress: "signerAddress",
  signerVestingType: "signerVestingType",
  signingOrderEnabled: "signingOrderEnabled",
  smsAuthRequired: "smsAuthRequired",
  requireProofSigner: "requireProofSigner",
  canAddRecipientGroup: "canAddRecipientGroup",
  defaultDocRequirement: "defaultDocRequirement",
  isCollaboratorUser: "isCollaboratorUser",
} as const;
const FORM_FIELDS = {
  recipients: "recipients",
  smsAuthRequired: "smsAuthRequired",
  showSigningOrder: "showSigningOrder",
} as const;

export const RECIPIENTS = FORM_FIELDS.recipients;

function RecipientDetailsSection({
  config,
  form,
  transaction,
  organization,
}: SectionComponentProps<RecipientDetails, RecipientDetailsOrg, unknown>) {
  const recipientColors = useRecipientColors();
  useEffect(() => {
    // this is to keep our form in sync with transaction data from the backend. new recipients (and capacities)
    // that are created in the form don't have ids until the backend returns on post update mutation
    // BIZ-5678: Make a utility function
    form.getValues(RECIPIENTS).forEach((formRecipient, signerIndex) => {
      const savedRecipient =
        signerIndex < transaction.customerSigners.length
          ? transaction.customerSigners[signerIndex]
          : null;
      if (!formRecipient.recipientId && savedRecipient) {
        form.setValue(`${RECIPIENTS}.${signerIndex}.recipientId`, savedRecipient.id);
      }
      formRecipient.capacities.forEach((capacity, capacityIndex) => {
        const savedCapacity =
          savedRecipient && capacityIndex < savedRecipient.capacities.length
            ? savedRecipient.capacities[capacityIndex]
            : null;
        if (!capacity.capacityId && savedCapacity) {
          form.setValue(
            `${RECIPIENTS}.${signerIndex}.capacities.${capacityIndex}.capacityId`,
            savedCapacity.id,
          );
        }
      });
    });
  }, [transaction]);
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const canEditSigners = hasPermissionFor("editTransactionSigners");
  const { control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: RECIPIENTS,
  });

  const { customerSigners } = transaction;

  const { addressSectionIsShowing, removeAddressSection, toggleAddressSection } =
    useRecipientAddressSections(customerSigners, form);

  const { defaultAuthenticationRequirement, defaultVerifiedEsignEnabled } = organization;
  const addressEnabledInConfig = showField(config, CONFIGS.signerAddress);
  const showSmsAuthRequiredCheckbox = showField(config, CONFIGS.smsAuthRequired);
  const signingOrderUIEnabled = showField(config, CONFIGS.signingOrderEnabled);
  const personallyKnownToNotary = useWatch({
    control,
    name: SIGNING_DETAILS_FORM_FIELDS.personallyKnownToNotary,
  });

  const smsAuthRequiredViaCheckbox = useWatch({
    control,
    name: FORM_FIELDS.smsAuthRequired,
  });
  const showSigningOrder = useWatch({
    control,
    name: FORM_FIELDS.showSigningOrder,
  });
  // This keeps track of the index and id of each recipient in the fields array.
  // It is used to help sort the recipients by their signing order.
  const recipientIdIndexMapping = fields.reduce<Record<string, number>>(
    (accum, { id }, index) => ({ ...accum, [id]: index }),
    {},
  );
  const watchedFields = useWatch({ control, name: RECIPIENTS });
  const controlledFields = useMemo(() => {
    return fields.map((field) => {
      return {
        ...field,
        // this is needed because fields only holds the default values and we need the order so that we can sort the list.
        // getValues does not subscribe to input changes but this works because we onBlur the order input
        order: form.getValues(`${RECIPIENTS}.${recipientIdIndexMapping[field.id]}.order`),
      };
    });
  }, [fields, watchedFields]);
  const [sortedRecipients, setSortedRecipients] = useState(controlledFields);
  const showFormSettings = signingOrderUIEnabled || showSmsAuthRequiredCheckbox;

  useEffect(() => {
    sortRecipients(controlledFields);
  }, [showSigningOrder, fields]);

  const getDefaultSmsRequirement = () => {
    return defaultAuthenticationRequirement === AuthTypes.SMS && smsAuthRequiredViaCheckbox;
  };

  const getNewSignerProofRequirements = () => {
    const proofRequirements: ProofRequirementField = [];
    if (getDefaultSmsRequirement()) {
      proofRequirements.push(ProofRequirementFormValues.SMS);
    }
    if (defaultVerifiedEsignEnabled) {
      proofRequirements.push(ProofRequirementFormValues.KBA);
    }
    return proofRequirements;
  };

  const addSigner = ({
    type,
  }: {
    type?: "representative" | "group";
  } = {}) =>
    append({
      recipientId: null,
      firstName: null,
      middleName: null,
      lastName: null,
      email: null,
      countryCode: null,
      phone: null,
      signingRequirement: SigningRequirementEnum.NOTARIZATION,
      address: null,
      proofRequirement: getNewSignerProofRequirements(),
      showProofRequirements: false,
      capacities:
        type === "representative"
          ? [{ capacityId: undefined, capacity: "", capacityType: undefined, representativeOf: "" }]
          : [],
      recipientGroup: type === "group" ? { sharedInboxEmail: null } : null,
      vestingType: VestingTypesEnum.BORROWER,
      order: fields.length + 1,
      colorHex: recipientColors.signers[fields.length % recipientColors.signers.length].card,
    });

  function sortRecipients(unsortedRecipients: typeof controlledFields = controlledFields) {
    const allHaveOrder = unsortedRecipients.every((field) => field.order);
    setSortedRecipients(
      unsortedRecipients.toSorted((a, b) => {
        if (!allHaveOrder) {
          // only sort if all recipients have an order, otherwise keep in same spot
          return 0;
        }
        if (a.order && b.order) {
          return a.order - b.order;
        }
        return 0;
      }),
    );
  }

  const addRecipientButton = () => {
    const maxRecipientCapacity = fields.length === MAX_SIGNERS;

    function getAddRecipientButtonDisabled(): {
      disabled: boolean;
      formattedMessage?: ReactNode;
      buttonLabel?: string;
    } {
      if (personallyKnownToNotary) {
        return {
          disabled: true,
          formattedMessage: (
            <FormattedMessage
              id="0dcc824c-0165-4db1-8068-22cb5a301f7d"
              defaultMessage="You have indicated that the notary personally knows the signer. That feature is only supported for transactions with one signer. To add additional signers, you must turn that feature off."
            />
          ),
          buttonLabel: intl.formatMessage(MESSAGES.toolTipPersonallyKnownToNotary),
        };
      } else if (maxRecipientCapacity) {
        return {
          disabled: true,
          formattedMessage: (
            <FormattedMessage
              id="0dcc824c-0165-4db1-8068-22cb5a301f7d"
              defaultMessage="You have reached the maximum number of signers allowed on the transaction."
            />
          ),
          buttonLabel: intl.formatMessage(MESSAGES.toolTipMaxRecipients),
        };
      } else if (config.isCollaboratorUser) {
        return {
          disabled: true,
        };
      }

      return { disabled: false };
    }

    const addRecipientButtonDisabled = getAddRecipientButtonDisabled();

    function getAddRecipientOptions() {
      const options = [
        {
          iconName: "name",
          title: intl.formatMessage(MESSAGES.signer),
          description: intl.formatMessage(MESSAGES.signerDescription),
          onClick: addSigner,
          "data-automation-id": "add-signer-option",
        },
        {
          iconName: "sign-meeting-on",
          title: intl.formatMessage(MESSAGES.representativeSigner),
          description: intl.formatMessage(MESSAGES.representativeSignerDescription),
          onClick: () => addSigner({ type: "representative" }),
          "data-automation-id": "add-representative-signer-option",
        },
      ];

      if (config.canAddRecipientGroup) {
        options.push({
          iconName: "employees",
          title: intl.formatMessage(MESSAGES.recipientGroup),
          description: intl.formatMessage(MESSAGES.recipientGroupDescription),
          // TODO: add functionality to the button in [BIZ-5618]
          onClick: () => addSigner({ type: "group" }),
          "data-automation-id": "add-recipient-group-option",
        });
      }

      return options;
    }

    const addRecipientButton = (
      <AddCardButton
        ariaLabel={intl.formatMessage(MESSAGES.addRecipient, { number: fields.length + 1 })}
        disabled={addRecipientButtonDisabled.disabled}
        options={getAddRecipientOptions()}
        text={
          <FormattedMessage
            id="6d274c81-65bd-4904-9cbd-391b33e5fd26"
            defaultMessage="Add recipient"
          />
        }
        data-automation-id="add-recipient-button"
      />
    );

    if (
      addRecipientButtonDisabled.disabled &&
      addRecipientButtonDisabled.buttonLabel &&
      addRecipientButtonDisabled.formattedMessage
    ) {
      return (
        <Tooltip
          target={addRecipientButton}
          triggerButtonLabel={addRecipientButtonDisabled.buttonLabel}
        >
          {addRecipientButtonDisabled.formattedMessage}
        </Tooltip>
      );
    }

    return addRecipientButton;
  };

  const items = [
    {
      label: (
        <FormattedMessage id="70368bf3-a012-493b-bd97-aaa150e66351" defaultMessage="Borrower" />
      ),
      value: VestingTypesEnum.BORROWER,
    },
    {
      label: (
        <FormattedMessage id="75ab01df-135e-4a72-bf9c-e780600d7ff4" defaultMessage="Nonborrower" />
      ),
      value: VestingTypesEnum.NON_BORROWER,
    },
  ];
  return (
    <>
      <SectionHeader iconName="employees" id={RECIPIENT_DETAILS_SECTION_ID}>
        {intl.formatMessage(MESSAGES.recipientDetails)}
      </SectionHeader>
      {showFormSettings && (
        <div className={Styles.formSettings}>
          {signingOrderUIEnabled && (
            <BinaryToggle
              value={showSigningOrder}
              onChange={() => {
                // we want to reset the order to 1 whenever user toggles signing order.
                // toggling off: it resets back to what the txn had the order as (if order was set)
                // toggling on: it initializes the order to 1, the use case here is a new txn with no order set
                // we are NOT resetting back to what the order was when page was initially loaded
                fields.forEach((_, index) => {
                  form.setValue(`${RECIPIENTS}.${index}.order`, index + 1);
                });
                form.setValue(FORM_FIELDS.showSigningOrder, !showSigningOrder);
              }}
              automationId="signing-order-toggle"
              label={
                <FormattedMessage
                  id="5c028993-f8e1-4fb7-9438-4adce59ab0f8"
                  defaultMessage="Set signing order"
                />
              }
            />
          )}
          {showSmsAuthRequiredCheckbox && (
            <div className={Styles.formSettingsItem}>
              <CheckboxLabel
                label={
                  <>
                    <strong>
                      <FormattedMessage
                        id="7cbec753-d9c4-4098-8d19-fda06b50c156"
                        defaultMessage="Require SMS authentication"
                      />
                    </strong>
                  </>
                }
                checkbox={
                  <Checkbox
                    {...form.register(FORM_FIELDS.smsAuthRequired)}
                    aria-invalid="false"
                    value="true"
                    disabled={readonlyField(config, CONFIGS.smsAuthRequired)}
                  />
                }
              />
              <Tooltip
                target={
                  <span className={Styles.tooltip}>
                    <Icon name="disclaimer" />
                  </span>
                }
              >
                <FormattedMessage
                  id="ab06f5c1-112d-4893-8bfd-5aabe7269d2f"
                  defaultMessage="All signers that are notarizing a document will need to verify their identity by entering an authentication code sent to their mobile phone."
                />
              </Tooltip>
            </div>
          )}
        </div>
      )}

      {sortedRecipients.map((recipient, sortedIndex) => {
        // recipient.capacities is set to null in specific cases (not sure why) when an element is removed from useFieldArray w/ remove method
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const isRepresentativeSigner = recipient.capacities && recipient.capacities.length > 0;
        const recipientIndex = recipientIdIndexMapping[recipient.id];
        function makeAutomationId(field: string) {
          // automation id uses sortedIndex so that tests can properly target a recipient from the sorted list
          return `recipient-${sortedIndex + 1}-${field}`;
        }

        const { recipientGroup } = recipient;

        let recipientCardIcon = "name";
        let recipientCardTitle = intl.formatMessage(MESSAGES.signer);

        if (isRepresentativeSigner) {
          recipientCardIcon = "sign-meeting-on";
          recipientCardTitle = intl.formatMessage(MESSAGES.representativeSigner);
        } else if (recipientGroup) {
          recipientCardIcon = "employees";
          recipientCardTitle = intl.formatMessage(MESSAGES.recipientGroup);
        }

        return (
          <CardWrapper key={recipient.id} scrollOnMount={recipient.recipientId === null}>
            {showSigningOrder && (
              <ConfiguredField
                config={config}
                configField={CONFIGS.signingOrderEnabled}
                form={form}
                name={`${RECIPIENTS}.${recipientIndex}.order`}
                aria-label={intl.formatMessage(MESSAGES.signingOrderInputLabel, {
                  number: sortedIndex + 1,
                })}
                as={TextInput}
                fullWidth={false}
                className={Styles.signingOrderField}
                registerOptions={{
                  valueAsNumber: true,
                  onChange: (event) => {
                    // the backend doesn't allow 0 index for order so we auto change to 1. this is done
                    // in onChange in order for the onBlur function to have the correct data to sort
                    if (event.target.value === "0") {
                      form.setValue(`${RECIPIENTS}.${recipientIndex}.order`, 1);
                    }
                  },
                  onBlur: () => {
                    sortRecipients();
                  },
                }}
              />
            )}
            <Card
              data-automation-id={makeAutomationId("card")}
              iconName={recipientCardIcon}
              title={recipientCardTitle}
              subtitle={
                recipientGroup ? intl.formatMessage(MESSAGES.recipientGroupDescription) : null
              }
              colorHex={recipientColors.signers[sortedIndex].card}
              removeButtonProps={
                fields.length > 1 && canEditSigners
                  ? {
                      label: intl.formatMessage(MESSAGES.removeRecipient, {
                        number: recipientIndex + 1,
                      }),
                      onClick: () => {
                        remove(recipientIndex);
                      },
                      "data-automation-id": makeAutomationId("remove"),
                    }
                  : undefined
              }
              actionMenu={
                // In the future if more items are added, use the conditional below on the items inside the array so only the "Add address" item hides. For now there's no other items so we hide the whole thing.
                addressEnabledInConfig && canEditSigners && !recipientGroup
                  ? {
                      items: [
                        {
                          selected: addressSectionIsShowing(recipientIndex),
                          onClick: () => toggleAddressSection(recipientIndex),
                          label: intl.formatMessage(MESSAGES.addAddress),
                          ariaLabel: intl.formatMessage(MESSAGES.toggleAddressAriaLabel, {
                            action: addressSectionIsShowing(recipientIndex) ? "Remove" : "Add",
                            number: sortedIndex + 1,
                          }),
                        },
                      ],
                      label: intl.formatMessage(MESSAGES.actionMenu, {
                        number: recipientIndex + 1,
                      }),
                    }
                  : undefined
              }
            >
              <SigningRequirement
                config={config}
                configField={CONFIGS.signerSigningRequirement}
                form={form}
                name={`${RECIPIENTS}.${recipientIndex}.signingRequirement`}
              />
              {recipientGroup ? (
                <ConfiguredEmailField
                  registerOptions={{
                    validate: {
                      validateDuplicateEmails: (value, formValues) => {
                        if (!value) {
                          return true;
                        }
                        const sharedInboxEmailValue = value as string;

                        // if the group email address is the same as another signer's email or
                        // another group email address, then it is not unique
                        const hasDuplicates = formValues.recipients.some(
                          (recipient, index) =>
                            recipientIndex !== index &&
                            (sharedInboxEmailValue.toLowerCase() ===
                              recipient.email?.toLowerCase() ||
                              (index < recipientIndex &&
                                sharedInboxEmailValue.toLowerCase() ===
                                  recipient.recipientGroup?.sharedInboxEmail?.toLowerCase())),
                        );

                        return !hasDuplicates || intl.formatMessage(MESSAGES.duplicateEmailError);
                      },
                    },
                  }}
                  data-automation-id={makeAutomationId("shared-inbox-email-field")}
                  config={config}
                  configField={CONFIGS.signerSharedInboxEmail}
                  form={form}
                  name={`${RECIPIENTS}.${recipientIndex}.recipientGroup.sharedInboxEmail`}
                  label={intl.formatMessage(MESSAGES.sharedInboxEmail)}
                  as={TextInput}
                />
              ) : (
                <>
                  <ConfiguredStyledSelectField
                    config={config}
                    configField={CONFIGS.signerVestingType}
                    form={form}
                    name={`${RECIPIENTS}.${recipientIndex}.vestingType`}
                    label={intl.formatMessage(MESSAGES.vestingType)}
                    ariaLabel={intl.formatMessage(MESSAGES.vestingType)}
                    data-automation-id={`${RECIPIENTS}.${recipientIndex}vesting-type`}
                    useStyledInput
                    placeholderAsLabel
                    searchable={false}
                    clearable={false}
                    items={items}
                    fullWidth
                  />

                  <SpacedMultipartFormRow>
                    <ConfiguredField
                      data-automation-id={`${recipientIndex > 0 ? "cosigner-" : ""}first-name-field`}
                      config={config}
                      configField={CONFIGS.signerFirstName}
                      form={form}
                      name={`${RECIPIENTS}.${recipientIndex}.firstName`}
                      label={intl.formatMessage(MESSAGES.firstName)}
                      as={TextInput}
                    />
                    <ConfiguredField
                      data-automation-id={`${recipientIndex > 0 ? "cosigner-" : ""}middle-name-field`}
                      config={config}
                      configField={CONFIGS.signerMiddleName}
                      form={form}
                      name={`${RECIPIENTS}.${recipientIndex}.middleName`}
                      label={intl.formatMessage(MESSAGES.middleName)}
                      as={TextInput}
                    />
                    <ConfiguredField
                      data-automation-id={`${recipientIndex > 0 ? "cosigner-" : ""}last-name-field`}
                      config={config}
                      configField={CONFIGS.signerLastName}
                      form={form}
                      name={`${RECIPIENTS}.${recipientIndex}.lastName`}
                      label={intl.formatMessage(MESSAGES.lastName)}
                      as={TextInput}
                    />
                  </SpacedMultipartFormRow>
                  <SpacedMultipartFormRow>
                    <ConfiguredEmailField
                      registerOptions={{
                        validate: {
                          validateDuplicateEmails: (value, formValues) => {
                            if (!value) {
                              return true;
                            }

                            const esignEmails = formValues.recipients.flatMap((signer) => {
                              if (signer.signingRequirement === SigningRequirementEnum.ESIGN) {
                                return signer.email;
                              }
                              return null;
                            });

                            const emailValue = value as string;
                            const recipients = formValues.recipients.slice();
                            recipients.splice(recipientIndex, 1);

                            const hasDuplicates =
                              Boolean(value) &&
                              recipients.some((recipient) => {
                                if (
                                  esignEmails.includes(recipient.email) ||
                                  config.validateDuplicateEmails
                                ) {
                                  return (
                                    emailValue.toLowerCase() === recipient.email?.toLowerCase()
                                  );
                                }
                                return false;
                              });
                            return (
                              !hasDuplicates || intl.formatMessage(MESSAGES.duplicateEmailError)
                            );
                          },
                        },
                      }}
                      data-automation-id="email-field"
                      config={config}
                      configField={CONFIGS.signerEmail}
                      form={form}
                      name={`${RECIPIENTS}.${recipientIndex}.email`}
                      label={intl.formatMessage(MESSAGES.email)}
                      as={TextInput}
                    />
                    {showField(config, CONFIGS.signerPhoneNumber) && (
                      <PhoneNumberFields
                        index={recipientIndex}
                        form={form}
                        disabled={readonlyField(config, CONFIGS.signerPhoneNumber)}
                        requiredByConfig={requiredField(config, CONFIGS.signerPhoneNumber)}
                        smsAuthRequiredViaCheckbox={smsAuthRequiredViaCheckbox}
                        showSmsAuthRequiredCheckbox={showSmsAuthRequiredCheckbox}
                        requireProofSigner={config.requireProofSigner}
                        showProofRequirementsName={`${RECIPIENTS}.${recipientIndex}.showProofRequirements`}
                        signingRequirementName={`${RECIPIENTS}.${recipientIndex}.signingRequirement`}
                      />
                    )}
                  </SpacedMultipartFormRow>
                  {addressEnabledInConfig && addressSectionIsShowing(recipientIndex) && (
                    <AddressSection
                      index={recipientIndex}
                      form={form}
                      name={`${RECIPIENTS}.${recipientIndex}.address`}
                      onRemove={() => removeAddressSection(recipientIndex)}
                      disabled={readonlyField(config, CONFIGS.signerAddress)}
                      required={requiredField(config, CONFIGS.signerAddress)}
                    />
                  )}

                  {isRepresentativeSigner && (
                    <Capacities disabled={!canEditSigners} index={recipientIndex} form={form} />
                  )}
                </>
              )}

              <ProofRequirements
                config={config}
                configField={CONFIGS.signerProofRequirements}
                form={form}
                name={`${RECIPIENTS}.${recipientIndex}.proofRequirement`}
                signingRequirementName={`${RECIPIENTS}.${recipientIndex}.signingRequirement`}
                showProofRequirementsName={`${RECIPIENTS}.${recipientIndex}.showProofRequirements`}
                defaultSmsRequirement={getDefaultSmsRequirement()}
                defaultKbaRequired={defaultVerifiedEsignEnabled}
                requiredOverride={recipientIndex === 0 ? true : undefined}
                makeAutomationId={(field) => makeAutomationId(`proof-requirement-${field}`)}
              />
            </Card>
          </CardWrapper>
        );
      })}
      <AddCardButtonWrapper>{canEditSigners && addRecipientButton()}</AddCardButtonWrapper>
    </>
  );
}

export type RecipientDetailsFormValues = {
  [FORM_FIELDS.recipients]: {
    recipientId: string | null;
    signingRequirement: SigningRequirementEnum | null;
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    email: string | null;
    countryCode: string | null;
    phone: string | null;
    address: Address | null;
    proofRequirement: ProofRequirementField | undefined;
    showProofRequirements: boolean;
    capacities: FormCapacity[];
    recipientGroup: {
      sharedInboxEmail: string | null;
    } | null;
    vestingType: VestingTypesEnum;
    order: number | null;
    colorHex: string;
  }[];
  [FORM_FIELDS.smsAuthRequired]: boolean;
  [FORM_FIELDS.showSigningOrder]: boolean;
};
type RecipientDetailsSubmitData = {
  customerSigners: {
    id: string | null;
    signingRequirement: SigningRequirementEnum | null;
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    address: Address | null;
    proofRequirement: ProofRequirementInputType | undefined;
    capacities: SubmitCapacity[];
    recipientGroup: {
      sharedInboxEmail: string | null;
    } | null;
    vestingType: string;
  }[];
};
export const RECIPIENT_DETAILS_SECTION = {
  Component: RecipientDetailsSection,
  configs: CONFIGS,
  getDefaultFormValues(transaction) {
    const { customerSigners, authenticationRequirement } = transaction;
    return {
      recipients: customerSigners.map(
        ({
          id,
          firstName,
          middleName,
          lastName,
          email,
          phone,
          address,
          signingRequirement,
          proofRequirement,
          capacities,
          recipientGroup,
          vestingType,
          order,
          recipientColor,
        }) => ({
          recipientId: id,
          firstName,
          middleName,
          lastName,
          email,
          vestingType,
          colorHex: recipientColor.card,
          order,
          countryCode: phone?.countryCode ?? null,
          phone: phone?.number ?? null,
          address: {
            // These fields are manually written out to exclude __typename because it gets auto added to queries
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            state: address.state,
            postal: address.postal,
            country: address.country,
          },
          signingRequirement:
            signingRequirement === SigningRequirementEnum.ESIGN
              ? signingRequirement
              : SigningRequirementEnum.NOTARIZATION,
          proofRequirement: transformProofRequirementForForm(proofRequirement) || [],
          showProofRequirements: false,
          capacities: capacities.map(({ capacity, capacityType, representativeOf, id }) => ({
            capacityId: id,
            representativeOf,
            capacityType,
            capacity,
          })),
          recipientGroup: recipientGroup
            ? { sharedInboxEmail: recipientGroup.sharedInboxEmail }
            : null,
        }),
      ),
      // TODO: needs to handle transaction level zip code auth?
      smsAuthRequired: authenticationRequirement === AuthTypes.SMS,
      // if there is an order established, we want to respect it and auto toggle signing order on
      showSigningOrder: customerSigners.some((signer) => signer.order !== null),
    };
  },
  getSubmitData({ sectionFormValues, sectionConfig }) {
    return {
      customerSigners: sectionFormValues.recipients.map(
        ({
          recipientId,
          firstName,
          middleName,
          lastName,
          email,
          countryCode,
          phone,
          address,
          proofRequirement,
          showProofRequirements,
          signingRequirement,
          capacities,
          recipientGroup,
          vestingType,
          order,
        }) => {
          return {
            id: recipientId,
            firstName,
            middleName,
            lastName,
            // Update after [BIZ-5334] is implemented to only use phoneNumber
            phoneNumber: countryCode && phone ? `+${countryCode}${phone}` : null,
            phone: countryCode && phone ? { countryCode, number: phone } : null,
            email,
            address,
            vestingType,
            proofRequirement:
              showProofRequirements &&
              (sectionConfig.requireProofSigner ||
                signingRequirement === SigningRequirementEnum.ESIGN)
                ? transformProofRequirementForSubmit(proofRequirement)
                : blankRequirementForSubmit(signingRequirement === SigningRequirementEnum.ESIGN),
            signingRequirement:
              sectionConfig.signerSigningRequirement.display !== "hidden" &&
              signingRequirement === SigningRequirementEnum.ESIGN
                ? signingRequirement
                : null,
            capacities: capacities.map(
              ({ capacityId, representativeOf, capacityType, capacity }) => ({
                id: capacityId,
                representativeOf,
                capacityType: capacityType!, // Our form validation will prevent this from being undefined
                capacity,
              }),
            ),
            recipientGroup: recipientGroup
              ? { sharedInboxEmail: recipientGroup.sharedInboxEmail }
              : null,
            // if we don't show signing order, pass undefined so that the values don't change in case it was set from API
            order: sectionFormValues.showSigningOrder ? order : undefined,
          };
        },
      ),
      requiredAuth:
        // depending on defaultDocRequirement is temporary, long term we should not create the
        // transaction w/ smsAuthRequired true by default if the workflow doesn't allow it
        sectionConfig.defaultDocRequirement === DocumentRequirementEnum.NOTARIZATION &&
        sectionFormValues.smsAuthRequired
          ? AuthTypes.SMS
          : AuthTypes.NONE,
    };
  },

  modifyConfig({ sectionConfig, permissions, transaction, organization }) {
    const modifiedConfig = { ...sectionConfig };
    if (!permissions.hasPermissionFor("editTransactionSigners")) {
      downgradeDisplay(modifiedConfig, "signerAddress", "readonly");
      downgradeDisplay(modifiedConfig, "signerCountryCode", "readonly");
      downgradeDisplay(modifiedConfig, "signerEmail", "readonly");
      downgradeDisplay(modifiedConfig, "signerFirstName", "readonly");
      downgradeDisplay(modifiedConfig, "signerLastName", "readonly");
      downgradeDisplay(modifiedConfig, "signerMiddleName", "readonly");
      downgradeDisplay(modifiedConfig, "signerPhoneNumber", "readonly");
      downgradeDisplay(modifiedConfig, "signerProofRequirements", "readonly");
      downgradeDisplay(modifiedConfig, "signerSigningRequirement", "readonly");
      downgradeDisplay(modifiedConfig, "signerVestingType", "readonly");
      downgradeDisplay(modifiedConfig, "signingOrderEnabled", "readonly");
      downgradeDisplay(modifiedConfig, "smsAuthRequired", "readonly");
    }

    if (
      !permissions.hasPermissionFor("manageOpenOrders") &&
      !usersOrgCreatedTransaction(transaction, organization)
    ) {
      downgradeDisplay(modifiedConfig, "signerVestingType", "readonly");
    }

    if (!isFeatureEnabled(transaction.organization, RECIPIENT_GROUPS_UI)) {
      modifiedConfig.canAddRecipientGroup = false;
    }
    if (!isFeatureEnabled(transaction.organization, MIXED_SIGNING_UI_SUPPORT)) {
      downgradeDisplay(modifiedConfig, "signerSigningRequirement", "hidden");
    }
    if (!isFeatureEnabled(transaction.organization, SIGNING_ORDER_UI_SUPPORT)) {
      downgradeDisplay(modifiedConfig, "signingOrderEnabled", "hidden");
    }
    return modifiedConfig;
  },
} satisfies SectionContract<
  RecipientDetailsFormValues,
  RecipientDetailsSubmitData,
  RecipientDetails,
  RecipientDetailsOrg,
  unknown,
  typeof CONFIGS
>;

// To Dos:
// Consider how transaction.authRequirement fits in with this section
